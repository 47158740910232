import React, { useState } from "react";
import { Input } from "@progress/kendo-react-inputs";
import Notice from "../components/Notice";
import { tryLogin, forgotPassword } from "../utils/API";
import { AuthService } from "../utils/auth";
import "./Login.css";

export default function Login({ children }) {
  const [user, set_user] = useState("");
  const [pass, set_pass] = useState("");
  const [email, set_email] = useState("");
  const [showEmail, set_showEmail] = useState(false);
  const [isValidEmail, set_isValidEmail] = useState(false);
  const [error, set_error] = useState(null);
  const [status, set_status] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!showEmail) {
      tryLogin(user, pass)
        .then((resp) => {
          if (resp.status === 1) {
            AuthService.setToken(resp.dataenum.token, resp.dataenum.isFirstTimeLogin);
          }else{
            set_error(resp.message);
            set_status(0);
          }
        })
        .catch((err) => {
          console.error("tryLogin: error:", err);
          set_error(err);
          set_status(0);
        });
    } else {
      forgotPassword(email)
        .then((resp) => {
          if (resp.status === 1) {
            set_showEmail(false);
            set_status(1);
            set_error(resp.message);
          } else {
            set_status(0);
            set_error(resp.message);
          }
        })
        .catch((err) => {
          console.error("tryLogin: error:", err);
          set_error(err);
        });
    }
  };

  const handleEmailChange = (e) => {
    set_email(e.value);
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(e.value)) {
      set_isValidEmail(false);
    } else {
      set_isValidEmail(true);
    }
  };

  return (
    <div className="Login">
      <form
        className="k-form"
        action="#"
        style={{ textAlign: "center", width: "300px" }}
      >
        {!showEmail && (
          <div>
            <fieldset className={"k-form-fieldset"}>
              <legend className={"k-form-legend"}>Einloggen</legend>
              <div className="mb-3">
                <Input
                  name="username"
                  style={{ width: "150px" }}
                  label="Benutzername"
                  pattern={"[A-Za-z]+"}
                  minLength={2}
                  required={true}
                  value={user}
                  onChange={(v) => set_user(v.value)}
                  style={{ width: "300px" }}
                />
              </div>
              <div className="mb-3">
                <Input
                  name="password"
                  type="password"
                  style={{ width: "150px" }}
                  label="Kennwort"
                  required={true}
                  minLength={6}
                  maxLength={18}
                  value={pass}
                  onChange={(v) => set_pass(v.value)}
                  style={{ width: "300px" }}
                />
              </div>
            </fieldset>
            <div className="k-form-buttons d-block">
              <button
                type={"submit"}
                className="k-button k-primary"
                disabled={!user || !pass || !user.length || !pass.length}
                onClick={handleSubmit}
              >
                Einloggen
              </button>
            </div>
            <div className="k-form-buttons d-block">
              <a href="#" onClick={() => set_showEmail(true)}>
                Kennwort vergessen?
              </a>
            </div>
          </div>
        )}
        {showEmail && (
          <div>
            <fieldset className={"k-form-fieldset"}>
              <legend className={"k-form-legend"}>Kennwort vergessen</legend>
              <div className="mb-3">
                <Input
                  name="email"
                  type="email"
                  style={{ width: "150px" }}
                  label="Email"
                  minLength={50}
                  required={true}
                  value={email}
                  onChange={handleEmailChange}
                  style={{ width: "300px" }}
                />
              </div>
            </fieldset>
            <div className="k-form-buttons d-block">
              <button
                type={"submit"}
                className="k-button k-primary"
                disabled={!email || !email.length || !isValidEmail}
                onClick={handleSubmit}
              >
                Neues Kennwort anfordern
              </button>
            </div>
            <div className="k-form-buttons d-block">
              <a href="#" onClick={() => set_showEmail(false)}>
                Einloggen
              </a>
            </div>
          </div>
        )}
      </form>

      <Notice error={error} onClose={() => set_error(null)} status={status}/>
    </div>
  );
}
