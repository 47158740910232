const restoredToken = sessionStorage.getItem("token")
  ? sessionStorage.getItem("token")
  : null;
const restoredTokenData = restoredToken ? parseJwt(restoredToken) : null;

export const AuthService = {
  token: restoredToken,
  tokenData: restoredTokenData,
  userData: {
    name: restoredTokenData ? restoredTokenData.unique_name : "n/a",
    email: restoredTokenData ? restoredTokenData.email : "n/a",
  },
  isAuthenticated() {
    return this.token && this.token.length > 0 && !isTokenExpired(this.token);
  },
  setToken(newToken, isFirstTimeLogin) {
    this.token = newToken;
    this.saveTokenInLocalStorage(newToken);
    const extracted_data = parseJwt(newToken);

    /* Email: "jrocket@example.com"
        GivenName: "UserName"
        Role: (2) ["Manager", "Project Administrator"]
        Surname: "UserSurname"
        aud: "juser.wellenbrecher.de"
        exp: 1619212722
        http://schemas.microsoft.com/ws/2008/06/identity/claims/role: "Manager"
        http://schemas.xmlsoap.org/ws/2005/05/identity/claims/email: "bee@example.com"
        http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name: "jrocket"
        iat: 1587676722
        iss: "Online JWT Builder"
        sub: "user@example.something" */

    this.userData.name = extracted_data.unique_name;
    this.userData.email = extracted_data.email;

    if (isFirstTimeLogin) {
      window.location.href = "/ChangePassword";
    } else {
      const loggedInRedirectURL = "/";
      window.location.href = loggedInRedirectURL;
    }
  },
  saveTokenInLocalStorage(token) {
    sessionStorage.setItem("token", token);
  },
  clearTokenInLocalStorage() {
    sessionStorage.removeItem("token");
  },
  logout() {
    this.userData.name = "n/a";
    this.userData.email = "n/a";
    this.token = null;
    this.clearTokenInLocalStorage();
    const logOutURL = "/";
    window.location.href = logOutURL;
  },
};

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const isTokenExpired = (token) => {
  // TODO
  return false;
};
