import React, { } from 'react';
import { Fade } from '@progress/kendo-react-animation';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
//import './Notice.css';

export default function Notice({ error, onClose, status }) {

    //onToggle = flag => this.setState({ [flag]: !this.state[flag] });

    return (
        <>
            <NotificationGroup
                style={{
                    alignItems: 'flex-start',
                    flexWrap: 'wrap-reverse'
                }}
            >
                <Fade enter={true} exit={true}>
                    {
                        error &&
                        <Notification
                            type={{ style: status === 1 ? 'success' : 'error', icon: true }}
                            closable={true}
                            onClose={() => onClose()}
                        //onClose={() => this.setState({ error: false })}
                        >
                            {/* <span>Oops! Something went wrong...</span> */}
                            <p>{error.toString()}</p>
                        </Notification>
                    }
                </Fade>
            </NotificationGroup>
        </>
    );
}
