import React, { useState, useEffect } from "react";
import { Input } from "@progress/kendo-react-inputs";
import Notice from "../components/Notice";
import { changePassword } from "../utils/API";
import { AuthService } from "../utils/auth";
import "./Login.css";

export default function ChangePassword(props) {
  const [currentPassword, set_currentPassword] = useState("");
  const [newPassword, set_newPassword] = useState("");
  const [newConfirmPassword, set_newConfirmPassword] = useState("");
  const [error, set_error] = useState(null);
  const [status, set_status] = useState(0);

  useEffect(() => {
    if (!AuthService.isAuthenticated()) {
      props.history.push("/login");
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    changePassword(AuthService.userData.email, currentPassword, newPassword)
      .then((resp) => {
        if (resp.status === 1) {
          props.history.push("/");
        } else {
          set_error(resp.message);
        }
      })
      .catch((err) => {
        console.error("tryLogin: error:", err);
        set_error(err);
      });
  };

  return (
    <div className="Login">
      <form
        className="k-form"
        action="#"
        style={{ textAlign: "center", width: "300px" }}
      >
        <div>
          <fieldset className={"k-form-fieldset"}>
            <legend className={"k-form-legend"}>Kennwort ändern</legend>
            <div className="mb-3">
              <Input
                name="currentPassword"
                type="password"
                style={{ width: "150px" }}
                label="Aktuelles Kennwort"
                required={true}
                minLength={6}
                maxLength={18}
                value={currentPassword}
                onChange={(v) => set_currentPassword(v.value)}
                style={{ width: "300px" }}
              />
              {currentPassword && currentPassword.length < 6 && (
                <div className="error">
                  Mindestens 6 Zeichen!
                </div>
              )}
            </div>
            <div className="mb-3">
              <Input
                name="newPassword"
                type="password"
                style={{ width: "150px" }}
                label="Neues Kennwort"
                required={true}
                minLength={6}
                maxLength={18}
                value={newPassword}
                onChange={(v) => set_newPassword(v.value)}
                style={{ width: "300px" }}
              />
              {newPassword && newPassword.length < 6 && (
                <div className="error">
                  Mindestens 6 Zeichen
                </div>
              )}
            </div>
            <div className="mb-3">
              <Input
                name="newConfirmPassword"
                type="password"
                style={{ width: "150px" }}
                label="Neues Kennwort wiederholen"
                required={true}
                minLength={6}
                maxLength={18}
                value={newConfirmPassword}
                onChange={(v) => set_newConfirmPassword(v.value)}
                style={{ width: "300px" }}
              />
              {newConfirmPassword && newConfirmPassword.length < 6 && (
                <div className="error">
                  Mindestens 6 Zeichen
                </div>
              )}

              {newPassword.length >= 6 &&
                newConfirmPassword.length >= 6 &&
                newConfirmPassword !== newPassword && (
                  <div className="error">
                    Passwörter stimmen nicht überein
                  </div>
                )}
            </div>
          </fieldset>
          <div className="k-form-buttons d-block">
            <button
              type={"submit"}
              className="k-button k-primary"
              disabled={
                !currentPassword ||
                currentPassword.length < 6 ||
                !newPassword ||
                newPassword.length < 6 ||
                !newConfirmPassword ||
                newConfirmPassword.length < 6 ||
                (newPassword.length >= 6 &&
                  newConfirmPassword.length >= 6 &&
                  newConfirmPassword !== newPassword)
              }
              onClick={handleSubmit}
            >
              Speichern
            </button>
          </div>
        </div>
      </form>

      <Notice error={error} onClose={() => set_error(null)} status={status}/>
    </div>
  );
}
