import faker from 'faker';

const calcWorkLength = (fromDate, toDate) => {
    const diffMs = toDate - fromDate; // milliseconds between
    const totalMinutes = Math.floor(diffMs / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (totalMinutes < 0)
        return "Invalid"
    else
        return hours + 'h ' + minutes + ' min'
}

const statuses = ['offen', 'abgeschlossen', 'wiedereröffnet']
const getPeriods = (count) => {
    let ret = [];

    for (let i = 0; i < count; i++) {
        ret.push(
            {
                id: i,
                state: faker.random.arrayElement(statuses),
                periodName: faker.date.month() + ' 2019', //'Januar 2019',
                description: 'Zeitraum 1.1.2019-31.1.2019',
                timeSheet: getFakeTimesheetDetails(faker.random.number({ min: 0, max: 50 }))
            }
        )

    }
    return ret;
}
export const getFakeTimesheetDetails = (count) => {
    let ret = [];
    for (let i = 0; i < count; i++) {
        const rndDate = faker.date.recent(15)
        const rndFrom = new Date(rndDate.getTime())
        const rndTo = new Date(rndDate.getTime())

        rndFrom.setHours(faker.random.arrayElement(['09', '10', '11', '12']), 0)
        rndTo.setHours(faker.random.arrayElement(['15', '17', '19', '20']), 0)

        ret.push({
            //date: fDate(faker.date.recent(15)),//.toDateString(),
            date: rndDate,//.toDateString(),
            //from: faker.random.number({min:0,max:60}), // faker.date.recent(5).toDateString(),
            //from: rndDate.setHours(faker.random.arrayElement(['09', '10', '11', '12',])), //faker.random.arrayElement(['09:00', '11:30', '15:20',]),
            from: rndFrom,
            //to: faker.date.recent(2).toDateString(),
            //to: faker.random.arrayElement(['21:00', '13:30', '16:00',]),
            //to: rndDate.setHours(faker.random.arrayElement(['15', '17', '19', '20',])),
            to: rndTo,
            workLength: calcWorkLength(rndFrom, rndTo),
            type: 'type1',
            detail: faker.lorem.words(3), //'Details here...',  
            comment: 'Some comment here.'
        })
    }
    return ret;
}

//export projectsData: ProjectSummary[] = [{
export const projectsDemoData = [
    {
        projectId: 1,
        clientName: 'Schmitz, Hermann',
        description: 'Beliebige Beschreibung',
        periods: [
            {
                id: 1,
                state: 'offen',
                periodName: 'Januar 2019',
                description: 'Zeitraum 1.1.2019-31.1.2019',
                timeSheet: getFakeTimesheetDetails(2)
            },
            {
                id: 2,
                state: 'abgeschlossen',
                periodName: 'Februar 2019',
                description: 'Zeitraum 1.2.2019-28.2.2019',
                timeSheet: getFakeTimesheetDetails(3)
            },
            {
                id: 3,
                state: 'offen',
                periodName: 'March 2019',
                description: 'Zeitraum 1.2.2019-28.2.2019',
                timeSheet: getFakeTimesheetDetails(2)
            }
        ]
    },
    {
        projectId: 2002,
        clientName: 'Müller, Petra',
        description: 'Beschreibung, muss noch definiert werden',
        periods: [
            {
                id: 1,
                state: 'offen',
                periodName: 'Januar 2019',
                description: 'Zeitraum 1.1.2019-31.1.2019',
                timeSheet: getFakeTimesheetDetails(5)
            },
            {
                id: 2,
                state: 'offen',
                periodName: 'Februar 2019',
                description: 'Zeitraum 1.2.2019-28.2.2019',
                timeSheet: []
            },
            {
                id: 3,
                state: 'offen',
                periodName: 'Februar 2019',
                description: 'Zeitraum 1.2.2019-28.2.2019',
                timeSheet: getFakeTimesheetDetails(42)
            }
        ]
    },
    {
        projectId: 7441,
        clientName: faker.name.firstName() + ', ' + faker.name.lastName(),//'Parks, John',
        description: faker.lorem.sentence(6),
        periods: getPeriods(6)
    },
    {
        projectId: 7442,
        clientName: faker.name.firstName() + ', ' + faker.name.lastName(),//'Parks, John',
        description: faker.lorem.sentence(6),
        periods: getPeriods(3)
    },
    {
        projectId: 7443,
        clientName: faker.name.firstName() + ', ' + faker.name.lastName(),//'Parks, John',
        description: faker.lorem.sentence(6),
        periods: getPeriods(24)
    },
    {
        projectId: 8001,
        clientName: faker.name.firstName() + ', ' + faker.name.lastName(),//'Parks, John',
        description: faker.lorem.sentence(6),
        periods: getPeriods(24)
    },
    {
        projectId: 8003,
        clientName: faker.name.firstName() + ', ' + faker.name.lastName(),//'Parks, John',
        description: faker.lorem.sentence(6),
        periods: getPeriods(24)
    }
];

/* export const parseEUDate = (date_str) => {
    // 22.11.2019.
    let parts = date_str.split('.')
    let d = new Date(parts[2], parseInt(parts[1]) - 1, parts[0])
    return d;
}
export const parseTime = (date_str, timStr) => {
    // 22.11.2019.
    //let parts = date_str.split('.')
    let timeParts = timStr.split(':')

    //let d = new Date(parts[2], parseInt(parts[1]) - 1, parts[0], timeParts[0], timeParts[1])
    let d = new Date(date_str)
    d.setHours(timeParts[0])
    d.setMinutes(timeParts[1])
    return d;
}

const fDate = (d) => {
    //let a = formatDate(d, "dd.MM.y.")
    //console.log('a:', a);
    return formatDate(d, "dd.MM.y.")
}
*/
