import { DropDownButton } from "@progress/kendo-react-buttons";
import React, { useState } from "react";
import avatar from "../assets/images/avatar.png";
import "./ProjectsPane.css";
import { Switch } from "@progress/kendo-react-inputs";
import { Status_rückfragen } from "../utils/API";
const items = [
  { label: "Projekt Nr", id: "projectId" },
  { label: "Name", id: "clientName" },
  {label: "Jugendamt", id:"jugendamt"},
  {label: "Koordinator", id:"Koordinator"},
  {label: "Wochenstunden", id:"Wochenstunden"}
];

export default function ProjectsPane({
  projects,
  selectedProject,
  onProjectItemClick,
  isShowAllProjects,
  onSwitchChange,
}) {
  const [data, set_data] = useState(projects);

  const onSortBy = (e) => {
    if (!e.item || !e.item.id) return;

    let sortField = e.item.id;

    data.sort((a, b) => {
      if (a[sortField] < b[sortField]) return -1;
      if (a[sortField] > b[sortField]) return 1;
      return 0;
    });

    set_data([...data]);
  };

  const isKorrekturStatus = (timesheets) => {
    let hasKorrekturStatus = timesheets.find(
      (x) => x["state"] === Status_rückfragen
    );
    if (hasKorrekturStatus !== undefined) {
      return (
        <div className="text-align-right">
          <span class="k-icon  k-i-warning k-i-exception k-i-error"></span>
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      {/* <h2>Projektauswahl</h2> */}

      <div>
        <div className="float-left">
          <DropDownButton
            text="Sortiere nach"
            items={items}
            textField="label"
            onItemClick={(e) => onSortBy(e)}
          />
        </div>
        {/* <div className="float-right">
          <Switch
            onChange={(e) => onSwitchChange(e)}
            checked={isShowAllProjects === 1 ? true : false}
            offLabel="Eigene"
            onLabel="Alle"
          />
        </div> */}
      </div>

      <br />

      <div className="ProjectItemsList">
        {data.map((val, i) => {
          return (
            <div
              key={i}
              onClick={() => onProjectItemClick(val.projectId, val.addnr, i)}
              className={
                "k-card ProjectItem " +
                (selectedProject && val.projectId === selectedProject.projectId
                  ? "ProjectItem-selected"
                  : "")
              }
            >
              <div className="ProjectItem-badgeWrap">
                <b>{val.clientName}</b>
                <span className="k-badge" title="Projektid">
                  {val.projectId}
                </span>
              </div>

              <p className="ProjectItem-desc">
                {val.description}
              </p>
              <p className="ProjectItem-Jugendamt">
               ASD: {val.jugendamt}
              </p>
              <p className="ProjectItem-Koordinator">
               Koordinator: {val.koordinator}
              </p>
              {isKorrekturStatus(val.timesheets)}
              {/* <div className="text-align-right">
                <span class="k-icon  k-i-warning k-i-exception k-i-error"></span>
              </div> */}
            </div>
          );
        })}
      </div>
    </>
  );
}
