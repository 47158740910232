import { projectsDemoData } from "./demo-data";
import { AuthService } from "./auth";

export const API_URL =
  // process.env.NODE_ENV === "production"
  // ? "https://192.168.255.32:44323"
  // : "https://192.168.255.32:44323";

   process.env.NODE_ENV === "production"
   ? "https://juser.wellenbrecher.de:44323/flst"
  : "https://192.168.255.77:44323";

const GET_PROJECTS_URL = API_URL + "/api/projects";
const TIMESHEETS_URL = API_URL + "/api/timesheets";
const ALLTIMESHEETS_URL = API_URL + "/api/timesheets/all";
const LEISTUNG_URL = API_URL + "/api/leistung";
const LEISTUNGDET_URL = API_URL + "/api/leistungdet";
const LOGIN_URL = API_URL + "/Users/authenticate";
const FORGOTPASSWORD_URL = API_URL + "/Users/forgotpassword";
const RESETPASSWORD_URL = API_URL + "/Users/resetpassword";
const CHANGEPASSWORD_URL = API_URL + "/Users/changepassword";
// const FEEDS_URL = "http://www.omdbapi.com/?apikey=96997df1&s=horror&y=2020&plot=full";
// const FEEDS_URL = "https://newsapi.org/v2/everything?q=bitcoin&from=2020-09-02&sortBy=publishedAt&apiKey=8c28fb6a68d449338bc3f3f60b16699d";
const GET_ADRESSEBYADDNR_URL = API_URL + "/api/adress/getAdresseByAdrnr";

const FEEDS_URL = API_URL + "/api/adress/feeds";
const INTRANET_LOGIN_URL = API_URL + "/Users/intranet";

export const Status_neu = "Neu";
export const Status_rückfragen = "Korrektur";
export const Status_abgeschlossen = "Koordinator";
export const Status_verarbeitet = "Verarbeitet";

const LOAD_DEMO_DATA = false;

export const fetchProjects = async (allproject, alltimesheets) => {
  if (LOAD_DEMO_DATA) {
    return projectsDemoData;
  }
  const url = `${GET_PROJECTS_URL}/${allproject}/${alltimesheets}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + AuthService.token,
      },
    });
    const responseTxt = await response.text(); // or use res.json() here
    return JSON.parse(responseTxt);
  } catch (e) {
    return e;
  }
};

export const getTimesheetDetails = async (forProject, forTimesheet) => {
  const url = `${TIMESHEETS_URL}/${forTimesheet.id}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + AuthService.token,
      },
    });
    const responseJSON = await response.json();


    return responseJSON;
  } catch (e) {
    return e;
  }
};

export const getAllTimesheetDetails = async (forProject, forTimesheet) => {
  const url = `${ALLTIMESHEETS_URL}/${forTimesheet.id}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + AuthService.token,
      },
    });
    const responseJSON = await response.json();


    return responseJSON;
  } catch (e) {
    return e;
  }
};

export const postTimeSheet = async (forProject, forTimesheet, params) => {
  params = { ...params };

  params.projectId = forProject.projectId;
  params.timesheetId = forTimesheet.id;
  params.adrnr = forTimesheet.adrnr;

  const payload = params;

  /* payload.typeid = params.type.id;
    payload.typetext = params.type.text;

    payload.detailid = params.detail.id;
    payload.detailtext = params.detail.text;

    params.type = undefined;
    params.detail = undefined; */

  try {
    const response = await fetch(TIMESHEETS_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + AuthService.token,
      },
      body: JSON.stringify(payload),
    });

    // after creating new resource, backend should return: 201 - Created
    // if(response.status === 201) return 'success';

    const responseTxt = await response.text(); // or use res.json() here


    return JSON.parse(responseTxt);
  } catch (e) {
    return e;
  }
};

export const putTimeSheet = async (forProject, forTimesheet, params) => {

  params = { ...params };

  params.projectId = forProject.projectId;
  params.timesheetId = forTimesheet.id;

  const url = TIMESHEETS_URL + "/" + params.id;
  const payload = params;

  /* payload.typeid = params.type.id;
    payload.typetext = params.type.text;

    payload.detailid = params.detail.id;
    payload.detailtext = params.detail.text;

    params.type = undefined;
    params.detail = undefined; */

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + AuthService.token,
      },
      body: JSON.stringify(payload),
    });

    // after update, backend should return: 200 - OK
    // if(response.status === 200) return 'success';

    const responseTxt = await response.text(); // or use res.json() here


    return JSON.parse(responseTxt);
    //return responseTxt;
  } catch (e) {
    return e;
  }
};

export const deleteTimeSheetDetail = async (
  forProject,
  forTimesheet,
  params
) => {
  const url = TIMESHEETS_URL + "/" + params;
  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + AuthService.token,
      },
    });

    // after update, backend should return: 200 - OK
    // if(response.status === 200) return 'success';

    const responseTxt = await response.text(); // or use res.json() here


    //return JSON.parse(responseTxt);
    return responseTxt;
  } catch (e) {
    return e;
  }
};

export const getLeistungsartData = async (forProjectId) => {

  const url = `${LEISTUNG_URL}/${forProjectId}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + AuthService.token,
      },
    });
    const responseJSON = await response.json();


    return responseJSON;
  } catch (e) {
    return e;
  }
};

export const getLeistungdetailData = async (forLeistungsartId) => {

  const url = `${LEISTUNGDET_URL}/${forLeistungsartId}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + AuthService.token,
      },
    });
    const responseJSON = await response.json();


    return responseJSON;
  } catch (e) {
    return e;
  }
};

export const tryLogin = async (username, password) => {
  try {
    const response = await fetch(LOGIN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (e) {
    console.error("tryLogin error:", e);
    return e;
  }
};

export const updateStatus = async (params) => {
  const url = TIMESHEETS_URL + "/" + params.id + "/" + params.text;
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + AuthService.token,
      },
    });
    const responseTxt = await response.text();
    return JSON.parse(responseTxt);
  } catch (e) {
    console.error("putTimeSheet error:", e);
    return e;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await fetch(FORGOTPASSWORD_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (e) {
    console.error("tryLogin error:", e);
    return e;
  }
};

export const resetPassword = async (userId, token, newPassword) => {
  try {
    const response = await fetch(RESETPASSWORD_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, token, newPassword }),
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (e) {
    console.error("tryLogin error:", e);
    return e;
  }
};

export const changePassword = async (email, currentPassword, newPassword) => {
  try {
    const response = await fetch(CHANGEPASSWORD_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + AuthService.token,
      },
      body: JSON.stringify({ email, currentPassword, newPassword }),
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (e) {
    console.error("tryLogin error:", e);
    return e;
  }
};

export const getFeeds = async () => {
  try {
    const response = await fetch(FEEDS_URL, {
      method: "GET",
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (e) {
    console.error("tryLogin error:", e);
    return e;
  }
};

export const getAdresseByAdrnr = async (adrnr) => {
  try {
    const url = `${GET_ADRESSEBYADDNR_URL}/${adrnr}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + AuthService.token,
      },
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (e) {
    console.error("getTimesheetDetails error:", e);
    return e;
  }
};

export const tryIntranetLogin = async (username, password) => {
  try {
    const response = await fetch(INTRANET_LOGIN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (e) {
    console.error("tryLogin error:", e);
    return e;
  }
};