import React, { useState, useEffect } from "react";
import Notice from "../components/Notice";
import { tryIntranetLogin } from "../utils/API";
import { AuthService } from "../utils/auth";

export default function Intranet(props) {
  const [user, set_user] = useState(props.match.params.username);
  const [pass, set_pass] = useState(props.match.params.password);
  const [error, set_error] = useState(null);
  const [status, set_status] = useState(0);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    set_user(query.get('username'))
    set_pass(query.get('password').replace(/ /g, "+"))
    if (user && pass)
      intranetLogin()
  });


  const intranetLogin = () => {
    tryIntranetLogin(user, pass)
      .then((resp) => {
        if (resp.status === 1) {
          AuthService.setToken(resp.dataenum.token, resp.dataenum.isFirstTimeLogin);
        } else {
          set_error(resp.message);
          set_status(0);
        }
      })
      .catch((err) => {
        console.error("tryLogin: error:", err);
        set_error(err);
        set_status(0);
      });
  };

  return (
    <div>
      <Notice error={error} onClose={() => set_error(null)} status={status} />
    </div>
  );
}
