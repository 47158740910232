import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
  registerForIntl,
  provideIntlService,
} from "@progress/kendo-react-intl";
import deMessages from "./de-DE.json";
//import './TimesheetsPane.css';
import { formatDate } from "@telerik/kendo-intl";
import React, { Fragment, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Status_neu,
  Status_rückfragen,
  Status_abgeschlossen,
  Status_verarbeitet,
} from "../utils/API";
import { Switch } from "@progress/kendo-react-inputs";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import deNumbers from "cldr-numbers-full/main/de/numbers.json";
import deLocalCurrency from "cldr-numbers-full/main/de/currencies.json";
import deCaGregorian from "cldr-dates-full/main/de/ca-gregorian.json";
import deDateFields from "cldr-dates-full/main/de/dateFields.json";

load(
  likelySubtags,
  currencyData,
  weekData,
  deLocalCurrency,
  deCaGregorian,
  deDateFields
);

loadMessages(deMessages, "de-DE");
export default function TimesheetsPane({
  timesheets,
  projectId,
  clientName,
  selectedTimesheetId,
  onTimesheetsGridRowClick,
  onStatus_Change,
  isShowAllTimeSheets,
  onSwitchChange,
}) {
  const [showAreYouSureForDelete, set_showAreYouSureForDelete] = useState(
    false
  );
  const [statusValue, set_statusValue] = useState(null);
  if (!timesheets) {
    return <p>No project selected</p>;
  }

  const onAreYouSureToDelete_yes = () => {
    set_showAreYouSureForDelete(false);
    onStatus_Change(statusValue);
    set_statusValue(null);
  };

  const onAreYouSureToDelete_cancel = () => {
    set_showAreYouSureForDelete(false);
    set_statusValue(null);
  };

  const onStatusChange = (event) => {
    set_statusValue(event.value);
    set_showAreYouSureForDelete(true);
  };

  const StatusCell = ({ dataItem }) => {
    const status = dataItem.state;
    const defaultItem = {
      id: dataItem.id,
      text: status,
    };
    let sizes = [];

    const valueRender = (element, value) => {
      if (!value) {
        return element;
      }
      
      const children = [
        <span
          className={
            "k-badge k-icon-md h-25 w-25 border-radius " +
            (dataItem.state === Status_neu ? "bg-green" : "") +
            (dataItem.state === Status_rückfragen ? "bg-red" : "") +
            (dataItem.state === Status_abgeschlossen ? "bg-yellow" : "") +
            (dataItem.state === Status_verarbeitet ? "bg-black" : "")
          }
          key={1}
          title={dataItem.state}
        ></span>,
      ];

      return React.cloneElement(element, { ...element.props }, children);
    };

    const itemRender = (li, itemProps) => {
      const itemChildren = (
        <Fragment>
          <span className="k-badge k-icon-md" title={dataItem.state}>
            {itemProps.dataItem.text}
          </span>
        </Fragment>
      );
      return React.cloneElement(li, li.props, itemChildren);
    };

    const getStatusCellFormat = () => {
      let isDisable = true;
      if (status === Status_neu || status === Status_rückfragen) {
        sizes = [
          {
            id: dataItem.id,
            text: Status_abgeschlossen,
          },
        ];

        isDisable = false;
      } else {
        isDisable = true;
      }
      // var now=new Date();
      // var ss=new Date(dataItem.datum);
      // console.log(now);
      // console.log(ss);
      // if(now<ss){
      //   isDisable = true; 
      //   console.log("Treffer");
      // }
      
      return (
        <>
          <DropDownList
            data={sizes}
            itemRender={itemRender}
            value={defaultItem}
            valueRender={valueRender}
            header={<span style={{ margin: "8px" }}>Ändere Status auf:</span>}
            onChange={(e) => onStatusChange(e)}
            style={{
              background: "none",
              border: "none",
            }}
            popupSettings={{
              width: "unset",
            }}
            disabled={isDisable}
          />
        </>
      );
    };

    return <td>{getStatusCellFormat()}</td>;
  };

  return (
    <>
      <div>
        <div className="float-left">
          <h3>
            Leistungsnachweise für Projekt #{projectId} ({clientName}){" "}
          </h3>
        </div>
        <div className="float-right">
          <h3>
            <Switch
              onChange={(e) => onSwitchChange(e)}
              checked={isShowAllTimeSheets === 1 ? true : false}
              offLabel="Neu"
              onLabel="Alle"
            />
          </h3>
        </div>
      </div>
      <LocalizationProvider language="de-DE">
        <IntlProvider locale="de-DE">
          <Grid
            onDataStateChange={(e) => {}}
            className="timesheetTable"
            style={{ maxHeight: "100%", clear: "both" }}
            rowHeight={30}
            data={timesheets.map((item) => ({
              ...item,
              selected: item.id === selectedTimesheetId,
            }))}
            selectedField="selected"
            onRowClick={(e) => onTimesheetsGridRowClick(e)}
          >
            {/* <GridColumn field="state" title="Status" cell={StatusCell} />
                <GridColumn title="Timesheet details" cell={CountCell} />
                <GridColumn field="periodName" title="Zeitraum" />
                <GridColumn field="description" title="Zusatzinformation" /> */}

            <GridColumn
              field="state"
              title="Status"
              cell={StatusCell}
              width="100px"
            />
            <GridColumn
              field="totalTimeSheetDetails"
              title="Einträge"
              width="100px"
            />
            <GridColumn
              field="datum"
              title="Zeitraum"
              cell={KendoGridDateCell}
              width="150px"
            />
            <GridColumn
              field="totalTimeSheetHours"
              title="Stunden"
              width="150px"
            />
            <GridColumn field="meldung" title="Meldung" />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
      {showAreYouSureForDelete && (
        <Dialog title="Bestätigung" onClose={onAreYouSureToDelete_cancel}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            Möchten Sie dieses Timesheet wirklich schließen?
          </p>
          <DialogActionsBar>
            <button className="k-button" onClick={onAreYouSureToDelete_cancel}>
              Nein
            </button>
            <button className="k-button" onClick={onAreYouSureToDelete_yes}>
              Ja
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}

class KendoGridDateCell extends React.Component {
  render() {
    const value = this.props.dataItem[this.props.field];
    return (
      <td>
        {provideIntlService(this).formatDate(new Date(value), "MMMM yyyy")}
      </td>
    );
  }
}

registerForIntl(KendoGridDateCell);
export { KendoGridDateCell };
/* const CountCell = ({ dataItem }) => {
    return (
        <td>
            {dataItem.timeSheet ? dataItem.timeSheet.length : '0'}
        </td>
    )
} */

// const StatusCell = ({ dataItem }) => {
//     const s = dataItem.state;
//     let sizes = [];
//     // const str = String(s).toLowerCase();

//     const valueRender = (element, value) => {
//         console.log('el val:', element, value);
//         if (!value) {
//             return element;
//         }

//         // const iconClass = getIconClassForState(s);
//         const children = [
//             <span className="k-badge k-icon-md"
//                 key={1}
//                 style={{
//                     // display: 'inline-block',
//                     // background: '#333',
//                     // color: '#fff',
//                     // borderRadius: '50%',
//                     // width: '50px',
//                 }}
//             >{dataItem.state}</span>
//         ];

//         return React.cloneElement(element, { ...element.props }, children);
//     }

//     const getIconClassForState = (state) => {
//         const s = String(state).toLowerCase();
//         let iconClass = ''
//         if (s === 'neu') iconClass = 'k-i-track-changes';
//         if (s === 'offen') iconClass = 'k-i-track-changes';
//         if (s === 'abgeschlossen') iconClass = 'k-i-check-circle';
//         if (s === 'wiedereröffnet') iconClass = 'k-i-unlock';
//         return iconClass;
//     }
//     const itemRender = (li, itemProps) => {
//         console.log('li, itemProps:', li, itemProps);
//         const itemChildren =
//             <Fragment>
//                 <span className="k-badge k-icon-md">{itemProps.dataItem}</span>
//             </Fragment>;
//         return React.cloneElement(li, li.props, itemChildren);
//     }

//     const defaultItem = s;

//     const getStatusCellFormat = () => {
//         // const iconClass = getIconClassForState(s);

//         // If status is new (Neu) or reopened (Wiedereröffnet) the user is allowed to change the status to closed (Abgeschlossen)
//         // If status is new, set to close is only allowed when it is concerning a previous month.
//         const str = String(s).toLowerCase();
//         if (str === 'neu' || str === 'wiedereröffnet') {
//             // set_sizes([s, 'abgeschlossen'])
//             sizes = [s, 'abgeschlossen']
//         }

//         return (
//             <>
//                 {/* <span className={"k-icon " + iconClass}></span> <span className="k-badge k-icon-md">{dataItem.state}</span> */}
//                 <DropDownList
//                     data={sizes}
//                     itemRender={itemRender}
//                     // defaultItem={defaultItem}
//                     value={defaultItem}
//                     valueRender={valueRender}
//                     header={<span style={{ margin: '8px' }}>Change status to:</span>}
//                     onChange={e => {
//                         // set_sizes(e.target.value)
//                     }}
//                     style={{
//                         //  width: "65px",
//                         background: 'none',
//                         border: 'none'
//                     }}
//                     popupSettings={{
//                         width: 'unset'
//                     }}
//                 />
//             </>
//         )
//     }

//     return (
//         <td>{getStatusCellFormat()}</td>
//     )
// }
