import React, { useEffect, useState } from "react";
import { DatePicker, DateInput  } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import {
  getLeistungsartData,
  getLeistungdetailData,
  getAdresseByAdrnr,
} from "../utils/API";
//import { parseEUDate, parseTime } from '../utils/API';
//import './NewTimesheetDialog.css';
import "@progress/kendo-react-intl";
/* import '@progress/kendo-drawing'
import '@progress/kendo-react-tooltip'
import '@progress/kendo-react-form' */

export default function NewTimesheetDialog({
  editingItem,
  onDialogCancel,
  onDialogSave,
  selectedProject,
  addnr,
  minDate,
  maxDate,
  selectedTimesheet,
  timesheetDetails,
  timesheetAllDetails
}) {
   const DEFAULT_FROM_DATE = minDate;

  const [leistungsart_vals, set_leistungdart_vals] = useState([]);
  const [leistungdetail_vals, set_leistungdetail_vals] = useState([]);
  const [date, set_date] = useState(
    editingItem ? new Date(editingItem.date) : DEFAULT_FROM_DATE
  );
  const [fromDate, set_fromDate] = useState(
    editingItem ? new Date(editingItem.from) : DEFAULT_FROM_DATE
  );
  const [toDate, set_toDate] = useState(
    editingItem ? new Date(editingItem.to) : DEFAULT_FROM_DATE
  );

  const [workLength, set_workLength] = useState(0);

  const [message, set_message] = useState(' ');

  const [type, set_type] = useState(
    editingItem
      ? {
        id: editingItem.typeid,
        text: editingItem.type,
      }
      : ""
  );

  const [detail, set_details] = useState(
    editingItem
      ? {
        id: editingItem.detailid,
        text: editingItem.detail,
      }
      : ""
  );

  const [comment, set_comment] = useState(
    editingItem ? editingItem.comment : ""
  );

  const [contents, set_contents] = useState(
    editingItem ? editingItem.contents : ""
  );
  const [city, set_city] = useState(editingItem ? editingItem.location : "");
  const [isChildEndangerment, set_isChildEndangerment] = useState(false);
  const [faceToFace, set_faceToFace] = useState(
    editingItem ? editingItem.faceToFace : false
  );

  const [endangerment, set_endangerment] = useState(
    editingItem ? editingItem.endangerment : false
  );

  const [participants, set_participants] = useState(
    editingItem ? editingItem.participants : ""
  );
  const [objectives, set_objectives] = useState(
    editingItem ? editingItem.objectives : ""
  );
  const [travelTime, set_travelTime] = useState(
    editingItem ? new Date(editingItem.traveltime) : DEFAULT_FROM_DATE
  );
  const [workLengthDecimal, set_workLengthDecimal] = useState(0);
  const [randomNumber, set_randomNumber] = useState(0);

  const [isDauerDezimal, set_isDauerDezimal] = useState(false);
  const [isStunden, set_isStunden] = useState(false);
  const [isFahrzeit, set_isFahrzeit] = useState(false);
  const [isLeistungsart, set_isLeistungsart] = useState(false);
  const [isLeistungsdetail, set_isLeistungsdetail] = useState(false);
  const [isInhalte, set_isInhalte] = useState(false);
  const [isTeilnehmer, set_isTeilnehmer] = useState(false);
  const [isOrt, set_isOrt] = useState(false);
  const [isFacetoFace, set_isFacetoFace] = useState(false);
  const [isKindeswohl, set_isKindeswohl] = useState(false);
  const [isZiel, set_isZiel] = useState(false);
  const [isFreitext, set_isFreitext] = useState(false);
  const [worktime, set_worktime] = useState(0);

  const [totalWorkTime, set_totalWorkTime] = useState(0);
  const [totalAllocatedTime, set_totalAllocatedTime] = useState(
    selectedTimesheet !== null ? selectedTimesheet.dTotalAllocatedTime : 0
  );
  const [isTimeExceed, set_isTimeExceed] = useState(false);

  useEffect(() => {
    if (totalWorkTime > totalAllocatedTime && totalAllocatedTime>0)  {
      set_message("Stunden über Kontingent!");
      set_isTimeExceed(true);
      
    } else {
      set_message("");
      set_isTimeExceed(false);
    }
    return () => { };
  }, [totalWorkTime]);

  useEffect(() => {
    function runOnce_loadDropDownsData() {
      //selectedProject
      //getLeistungsartData(editingItem.pronr) // ERR when NEW
      // selectedProject.projectId
      getLeistungsartData(addnr)
        .then((resp) => {
          // [ {id:22,text:'aaa'}, ...]
          set_leistungdart_vals(resp);
        })
        /* .then(resp => {
                }) */
        .catch((err) => {
          console.error("getLeistungsartData catched:", err);
          set_leistungdart_vals([]);
        });

      if (editingItem) {
        getLeistungdetailData(editingItem.typeid)
          .then((resp) => {
            // [ {id:22,text:'aaa'}, ...]
            set_leistungdetail_vals(resp);
          })
          .catch((err) => {
            set_leistungdetail_vals([]);
          });
      }
    }

    runOnce_loadDropDownsData();
    return () => { };
  }, []);

  useEffect(() => {
    getAdress();
    return () => { };
  }, []);

  useEffect(() => {
    const calcWorkLength = () => {
      console.log("Calcworklength");
      console.log(fromDate);
      console.log(toDate);
      const diffMs = toDate - fromDate; // milliseconds between
      const totalMinutes = Math.floor(diffMs / (1000 * 60));
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      set_worktime(totalMinutes);
      if (totalMinutes < 0) {
        set_message("Startuhrzeit vor Endeuhrzeit!");
        set_isTimeExceed(false);
      } else {
        if (isTimeExceed){
          set_message("Stunden über Kontingent!");
        }
          else
          {
            set_message("");

          }
        set_workLength(hours + "h " + minutes + "min");
        set_workLengthDecimal((hours + minutes / 60).toFixed(2));

        //
        if (editingItem !== null) {
          if (totalMinutes > editingItem["worktime"]) {
            let tempTimeSheet = selectedTimesheet.timesheetdet.filter(
              (x) => x["id"] !== editingItem["id"]
            );

            set_totalWorkTime(
              totalMinutes +
              tempTimeSheet.reduce(
                (total, currentValue) =>
                  (total = total + currentValue.worktime),
                0
              )
            );
          } else {
            set_totalWorkTime(
              selectedTimesheet.timesheetdet.reduce(
                (total, currentValue) =>
                  (total = total + currentValue.worktime),
                0
              )
            );
          }
        } else {
          set_totalWorkTime(
            totalMinutes +
            selectedTimesheet.timesheetdet.reduce(
              (total, currentValue) =>
                (total = total + currentValue.worktime),
              0
            )
          );
        }
      }

      set_randomNumber(0);
    };

    calcWorkLength();

    return () => { };
  }, [fromDate, toDate]); // run when fromDate or toDate changes

  const fromDate_onChange = (e) => {
    var mydate = new Date(e.value);
    var basedate=new Date(date);
    var fd=new Date(basedate.getFullYear(),basedate.getMonth(),basedate.getDate(),mydate.getHours(),mydate.getMinutes(),0) 
    set_fromDate(fd);
  };

  const toDate_onChange = (e) => {
    set_toDate(e.value);
    var mydate = new Date(e.value);
    var basedate=new Date(date);
    var fd=new Date(basedate.getFullYear(),basedate.getMonth(),basedate.getDate(),mydate.getHours(),mydate.getMinutes(),0) 
    set_toDate(fd);
  };

  const travelTime_onChange = (e) => {
    set_travelTime(e.target.value);
  };

  const onClick_Save = (e) => {
    /* onDialogSave({
            date: date, //.getTime(),
            from: fromDate, //.getTime(),
            to: toDate, //.getTime(),
            workLength: workLength,
            type: type,
            detail: detail,
            comment: comment
        }) */


    // if (isLeistungsdetail && !detail) {
    //   console.error("onClick_Save return, detail is null");
    //   return;
    // }
    if(date>maxDate) {
      alert("Datum außerhalb Bereich")
      return;
    }
    if(date<minDate) {
      alert("Datum außerhalb Bereich")
      return;
    }

    if (workLengthDecimal==0){
      set_message("Keine Zeit erfasst!");

      console.error("onClick_Save return, workLengthDecimal=0");
      return;
    }
    else{
      set_message("");
    }

    if (isLeistungsart && !type) {
      set_message("Mussfelder ausfüllen!");
      console.error("onClick_Save return, type is null");
      return;
    }
    else{
      if (isTimeExceed){
        set_message("Stunden über Kontingent!");
      }
        else
        {
          set_message("");

        }

    }

    // if (isTimeExceed) {
    //   
    //   return;
    // }
    // else{
    //   set_message("");
    // }
    // if (!type || !detail) {
    //   console.error("onClick_Save return, type or detail is null");
    //   return;
    // }

    
    let isDateOverLap = false;
    let tempTimeSheet = editingItem ? timesheetDetails.filter(x => x.id !== editingItem.id) : timesheetDetails
    tempTimeSheet.forEach(element => {
      let df = new Date(element.from)
      let dt = new Date(element.to)
      //debugger;
      if (
        (toDate<=df) || (fromDate>=dt)
        // (compareStart >= fromDate && compareStart <= toDate ||
        // fromDate >= compareStart && fromDate < compareEnd)
        )
        {
         // console.log("Kein Treffer")
        }
        else
         {
        isDateOverLap = true
        return false;
      }
    });

    if (isDateOverLap) {
      alert("Sie haben eine Terminüberlappung!")
      return;
    }

    let tempTimeSheetA = editingItem ? timesheetAllDetails.filter(x => x.id !== editingItem.id) : timesheetAllDetails
    tempTimeSheetA.forEach(element => {
      let df = new Date(element.from)
      let dt = new Date(element.to)
      //debugger;
      if (
        (toDate<=df) || (fromDate>=dt)
        // (compareStart >= fromDate && compareStart <= toDate ||
        // fromDate >= compareStart && fromDate < compareEnd)
        )
        {
         // console.log("Kein Treffer")
        }
        else
         {
        isDateOverLap = true
        return false;
      }
    });

    if (isDateOverLap) {
      alert("Sie haben eine Terminüberlappung!")
      return;
    }


    onDialogSave({
      ...editingItem,
      date: date, //.getTime(),
      from: fromDate, //.getTime(),
      to: toDate, //.getTime(),
      workLength: workLength,
      /* type: type,
            //type: type.id,
            detail: detail, */
      typeid: type.id,
      typetext: type.text,
      //type: type.id,
      detailid: detail.id,
      detailtext: detail.text,
      comment: comment,
      location: city,
      contents: contents,
      faceToFace: faceToFace,
      endangerment: endangerment,
      participants: participants,
      objectives: objectives,
      travelTime: travelTime,
      worktime: worktime,
    });
  };

  const onDatumChange = (e) => {
    var mydate = new Date(e.value);
    var from = new Date(fromDate);
    var to = new Date(toDate);
    var today = new Date();
    var fd=new Date();
    var td=new Date();
    if(from.getHours()==0){
       fd=new Date(mydate.getFullYear(),mydate.getMonth(),mydate.getDate(),today.getHours(),0,0)    //XXXX
       td=new Date(mydate.getFullYear(),mydate.getMonth(),mydate.getDate(),today.getHours()+1,0,0)    //XXXX
    }
    else{
      fd=new Date(mydate.getFullYear(),mydate.getMonth(),mydate.getDate(),from.getHours(),from.getMinutes(),0)    //XXXX
      td=new Date(mydate.getFullYear(),mydate.getMonth(),mydate.getDate(),to.getHours(),to.getMinutes(),0)    //XXXX
    }
    set_fromDate(fd);
    set_toDate(td);
    console.log(mydate);
    console.log(fd)
    console.log(td)

    set_date(e.value);
  };

  const onLesitungsartChange = (value) => {
    set_type(value);
    // remove if previous left
    set_leistungdetail_vals([]);

    getLeistungdetailData(value.id)
      .then((resp) => {
        // [ {id:22,text:'aaa'}, ...]
        set_leistungdetail_vals(resp);
      })
      .catch((err) => {
        console.error("onLesitungsartChange catched:", err);
        set_leistungdetail_vals([]);
      });
  };

  const getForm = () => {
    return (
      // <form className="k-form" onSubmit={handleSubmit}> */
      <form
        className="k-form-inline"
      // style={{ width: '80%', height: '80%' }}
      >
        <fieldset>
          <legend>Arbeitszeit erfassen</legend>
          {/* <label className="k-form-field">
                <span>Von:</span>
                <Input
                  name="input"
                  value={fromDate}
                />
              </label> */}

          <div className="col-xs-12 col-sm-6 example-col">
            <div className="form-row">
              <div className="form-col">
                <div className="k-form-field">
                  <span>Datum:</span>
                  <DatePicker
                    format={"dd.MM.yyyy"}
                    value={date}
                    onChange={onDatumChange}
                    min={minDate}
                    max={maxDate}
                    required={true}
                  />
                </div>

                <div className="k-form-field">
                  <span>von:</span>
                  <DateInput
                    format="HH:mm"
                    popupSettings={{ popupClass: "timePickerPopUpClass" }}
                    value={fromDate}
                    onChange={fromDate_onChange}
                    required={true}
                  />
                </div>

                <div className="k-form-field">
                  <span>bis:</span>
                  <DateInput
                    format="HH:mm"
                    popupSettings={{ popupClass: "timePickerPopUpClass" }}
                    value={toDate}
                    onChange={toDate_onChange}
                    required={true}
                  />
                </div>
              </div>

              <div className="form-col">
                {isFacetoFace && (
                  <div
                    className="k-form-field"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="facetoface">Face to face:</div>
                    <div className="radioss">
                      <input
                        type="radio"
                        name="group"
                        id="radio1"
                        className="k-radio"
                        checked={faceToFace}
                        onChange={function (e) {
                          if (e.target.checked) {
                            set_faceToFace(true);
                          } else {
                            set_faceToFace(false);
                          }
                        }}
                      />
                      <label className="k-radio-label" htmlFor="radio1">
                        Ja
                      </label>

                      <input
                        type="radio"
                        name="group"
                        id="radio2"
                        className="k-radio"
                        checked={faceToFace === false}
                        onChange={function (e) {
                          if (e.target.checked) {
                            set_faceToFace(false);
                          } else {
                            set_faceToFace(true);
                          }
                        }}
                      />
                      <label className="k-radio-label" htmlFor="radio2">
                        Nein
                      </label>
                    </div>
                  </div>
                )}

                {isKindeswohl && (
                  <div
                    className="k-form-field"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="facetoface">Kindeswohl:</div>
                    <div className="radioss">
                      <input
                        type="radio"
                        name="group_endangerment"
                        id="radio3"
                        className="k-radio"
                        checked={endangerment}
                        onChange={function (e) {
                          if (e.target.checked) {
                            set_endangerment(true);
                          } else {
                            set_endangerment(false);
                          }
                        }}
                      />
                      <label className="k-radio-label" htmlFor="radio3">
                        Ja
                      </label>

                      <input
                        type="radio"
                        name="group_endangerment"
                        id="radio4"
                        className="k-radio"
                        checked={endangerment === false}
                        onChange={function (e) {
                          if (e.target.checked) {
                            set_endangerment(false);
                          } else {
                            set_endangerment(true);
                          }
                        }}
                      />
                      <label className="k-radio-label" htmlFor="radio4">
                        Nein
                      </label>
                    </div>
                  </div>
                )}

                {isStunden && (
                  <div
                    className="k-form-field"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="facetoface">Bewilligte Stunden:</div>
                    <div className="radioss">{randomNumber}</div>
                  </div>
                )}

                {isFahrzeit && (
                  <div
                    className="k-form-field"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="facetoface">Fahrzeit:</div>
                    <div className="radioss">
                      <DateInput
                        format="HH:mm"
                        popupSettings={{ popupClass: "timePickerPopUpClass" }}
                        value={travelTime}
                        onChange={travelTime_onChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <br />
            <div className="form-row">
              <div className="form-col">
                <label className="k-form-field">
                  <span>Dauer:</span>
                  <Input
                    name="input"
                    required={true}
                    disabled={true}
                    value={workLength}
                    onChange={(e) => set_workLength(e.target.value)}
                  />
                </label>
              </div>
              <div className="form-col">
                {isDauerDezimal && (
                  <div className="k-form-field">
                    <div className="facetoface">Dauer Dezimal</div>
                    <div className="radioss">{workLengthDecimal}</div>
                  </div>
                )}
              </div>
            </div>
            <br />
            <div className="form-row text-danger">
                  <Input className="message text-danger"
                    name="message"
                    value={message}
                  />
            </div>
            {/* {isTimeExceed && (
              <div className="form-row text-danger">Stunden über Kontingent</div>
            )} */}

            <br />

            {isTeilnehmer && (
              <span className="k-form-field">
                <span>Teilnehmer:</span>
                <Input
                  name="input"
                  className="k-textarea myTextArea"
                  required={true}
                  value={participants}
                  onChange={(e) => set_participants(e.target.value)}
                />
              </span>
            )}

            {isOrt && (
              <label className="k-form-field">
                <span>Ort:</span>
                <Input
                  className="k-textarea myTextArea"
                  name="input"
                  required={true}
                  value={city}
                  onChange={(e) => set_city(e.target.value)}
                />
              </label>
            )}

            {isLeistungsart && (
              <span className="k-form-field">
                <span>Leistungsart:</span>
                <DropDownList
                  //name="ddl1"
                  required={true}
                  defaultValue="Auswahl..."
                  data={leistungsart_vals}
                  textField="text"
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  popupSettings={{ popupClass: "timePickerPopUpClass" }}
                  value={type}
                  //onChange={e => set_type(e.target.value)}
                  onChange={(e) => onLesitungsartChange(e.target.value)}
                />
              </span>
            )}

            {isLeistungsdetail && (
              <span className="k-form-field">
                <span>Leistungdetail:</span>
                <DropDownList
                  //name="ddl2"
                  defaultValue="Auswahl..."
                  required={false}
                  data={leistungdetail_vals}
                  textField="text"
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  className="timePickerPopUpClass"
                  popupSettings={{ popupClass: "timePickerPopUpClass" }}
                  value={detail}
                  onChange={(e) => set_details(e.target.value)}
                />
              </span>
            )}

            {isZiel && (
              <label className="k-form-field">
                <span>Ziel (intern):</span>
                <Input
                  className="k-textarea myTextArea"
                  name="input"
                  required={false}
                  value={objectives}
                  onChange={(e) => set_objectives(e.target.value)}
                />
              </label>
            )}

            {isInhalte && (
              <label className="k-form-field">
                <span>Inhalt(extern):</span>
                <textarea
                  className="k-textarea myTextArea"
                  value={contents}
                  onChange={(e) => set_contents(e.target.value)}
                ></textarea>
              </label>
            )}

            {isFreitext && (
              <label className="k-form-field">
                <span>Freitext(intern):</span>
                <textarea
                  className="k-textarea myTextArea"
                  value={comment}
                  onChange={(e) => set_comment(e.target.value)}
                ></textarea>
              </label>
            )}

          </div>
        </fieldset>
      </form>
    );
  };

  return (
    <Dialog
      width={640}
      // height={'100%'}
      title={
        editingItem ? "Stundennachweis bearbeiten" : "Neuer Stundennachweis"
      }
      onClose={onDialogCancel}
    >
      <div className="NewTimesheetDialog">
        <div className="row example-wrapper">
          <div className="col-xs-12 col-sm-6 offset-sm-3 example-col">
            <div className="card">
              <div className="card-block">
                {/* success &&
                                    <div className="alert alert-success">
                                        <p style={{ margin: "25px", textAlign: "center" }}>Form submitted!</p>
                                    </div> */}
                {
                  //!success && getForm()
                }
                {getForm()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogActionsBar>
        {/* success &&
                    <button className="k-button" onClick={props.onDialogCancel}>Close</button> */}
        <button
          className="k-button"
          //style={{ 'display': success ? 'none' : 'inline-block' }}
          onClick={onDialogCancel}
        >
          Abbrechen
        </button>
        <button
          className="k-button k-primary"
          //style={{ 'display': success ? 'none' : 'inline-block' }}
          //disabled={!editForm.valid}
          onClick={onClick_Save}
        >
          Speichern
        </button>
      </DialogActionsBar>
    </Dialog>
  );

  async function getAdress() {
    getAdresseByAdrnr(addnr)
      .then((resp) => {
        if (resp !== null) {
          set_isDauerDezimal(
            resp.flstDauerDezimal !== null ? resp.flstDauerDezimal : false
          );
          set_isStunden(resp.flstStunden !== null ? resp.flstStunden : false);
          set_isFahrzeit(
            resp.flstFahrzeit !== null ? resp.flstFahrzeit : false
          );
          set_isLeistungsart(
            resp.flstLeistungsart !== null ? resp.flstLeistungsart : false
          );
          set_isLeistungsdetail(
            resp.flstLeistungsdetail !== null ? resp.flstLeistungsdetail : false
          );
          set_isInhalte(resp.flstInhalte !== null ? resp.flstInhalte : false);
          set_isTeilnehmer(
            resp.flstTeilnehmer !== null ? resp.flstTeilnehmer : false
          );
          set_isOrt(resp.flstOrt !== null ? resp.flstOrt : false);
          set_isFacetoFace(
            resp.flstFacetoFace !== null ? resp.flstFacetoFace : false
          );
          set_isKindeswohl(
            resp.flstKindeswohl !== null ? resp.flstKindeswohl : false
          );
          set_isZiel(resp.flstZiel !== null ? resp.flstZiel : false);
          set_isFreitext(
            resp.flstFreitext !== null ? resp.flstFreitext : false
          );
        }
      })
      .catch((err) => {
        console.error("getAdresse catched:", err);
      });
  }
}
