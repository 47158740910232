import "@progress/kendo-theme-default/dist/all.css";
//import 'font-awesome/css/font-awesome.min.css';
//import './assets/css/all.css';
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import TopBar from "./components/TopBar";
import Dashboard from "./pages/Dashboard";
import { AuthService } from "./utils/auth";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import ChangePassword from "./components/ChangePassword";
import Intranet from "./components/Intranet";
import Impressum from "./components/Impressum";
import Datenschutzerklärung from "./components/Datenschutzerklärung";
// import { useHistory } from 'react-router-dom'

export default function App(props) {
  /* const [lastError, set_lastError] = useState(null);
  const [isLoading, set_isLoading] = useState(true);
  const [isAuthenticated, set_isAuthenticated] = useState(false); // test with true

  // let history = useHistory()

  useEffect(() => {
    async function checkAuth() {

      // if (USE_DEMO) {
      //   set_isAuthenticated(true);
      //   set_isLoading(false);
      //   return;
      // }

      console.log('checkAuth, isAuthenticated:', AuthService.isAuthenticated());

      // check if authenticated
      if (AuthService.isAuthenticated()) {
        set_isAuthenticated(true);
        set_isLoading(false);
        return;
      }

      set_isAuthenticated(false);
    }

    checkAuth();
    return () => { }
  }, []); */

  return (
    <div className="App">
      <Router>
        {/* <Router basename="/flst"> */}
        {/* <div> */}

        <TopBar>
          <ul>
            {/*  <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/projects">Projects</Link>
            </li>
            <li>
              <Link to="/timesheet">Timesheet</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li> */}
            {/* <li>
              <Link to="/">Dashboard</Link>
            </li> */}
          </ul>
        </TopBar>
        {/* <hr /> */}

        {/* <Link to="/projects">Projects / Projektübersicht</Link> */}

        <Switch>
          <SecretRoute exact path="/" component={Dashboard} />
          {/* <Route path="/projects" component={Projects} />
          <Route path="/timesheet/:id" component={Timesheet} />
          <Route path="/dashboard" component={Dashboard} /> */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/intranet" component={Intranet} />
          <Route exact path="/ResetPassword/:id/:token" component={ResetPassword} />

          <Route exact path="/Dashboard" component={Dashboard} />
          <Route exact path="/ChangePassword" component={ChangePassword} />
          <Route exact path="/Impressum" component={Impressum} />
          <Route exact path="/Datenschutzerklärung" component={Datenschutzerklärung} />
          <Route component={NoMatch} />
        </Switch>
        {/* </div> */}
      </Router>
    </div>
  );
}

function NoMatch({ location }) {
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

const SecretRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      AuthService.isAuthenticated() === true ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);
