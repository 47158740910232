import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { formatDate } from "@telerik/kendo-intl";
import React, { useState, useEffect } from "react";
import { ColumnMenu } from "./columnMenu.jsx";
//import './TimesheetDetailsPane.css';
import { process } from "@progress/kendo-data-query";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import deMessages from "./de-DE.json";
loadMessages(deMessages, "de-DE");

export default function TimesheetDetailsPane({
  selectedTimesheet,
  timesheetDetails,
  onEdit,
  onDelete,
  onClickNewTimesheet,
  isAllowCUD,
}) {
  const [showAreYouSureForDelete, set_showAreYouSureForDelete] = useState(
    false
  );
  const [idToDelete, set_idToDelete] = useState(null);

  const onEditClick = (p) => {
    onEdit(p.dataItem);
  };

  const onDeleteClick = (p) => {
    set_idToDelete(p.dataItem.id);
    set_showAreYouSureForDelete(true);
  };

  const onAreYouSureToDelete_yes = () => {
    set_showAreYouSureForDelete(false);
    onDelete(idToDelete);
    set_idToDelete(null);
  };

  const onAreYouSureToDelete_cancel = () => {
    set_showAreYouSureForDelete(false);
    set_idToDelete(null);
  };

  const CustomEditRemoveCell = (cell_props) => {
    return (
      <td>
        <Button
          icon="edit"
          title="Edit"
          onClick={(e) => onEditClick(cell_props)}
          disabled={!isAllowCUD}
        ></Button>
        <Button
          icon="minus-circle"
          title="Delete"
          onClick={(e) => onDeleteClick(cell_props)}
          disabled={!isAllowCUD}
        ></Button>
      </td>
    );
  };

  const [list, set_list] = useState(timesheetDetails);
  const [dataState, set_dataState] = useState(null);

  const [totalWorkTime, set_totalWorkTime] = useState(0);

  useEffect(() => {
    set_list(timesheetDetails);
    return () => {};
  }, [timesheetDetails]);

  useEffect(() => {
    if (
      selectedTimesheet !== null &&
      selectedTimesheet !== undefined &&
      selectedTimesheet.timesheetdet.length > 0
    ) {
      set_totalWorkTime(
        selectedTimesheet.timesheetdet.reduce(
          (total, currentValue) => (total = total + currentValue.worktime),
          0
        )
      );
    } else {
      set_totalWorkTime(0);
    }
    return () => {};
  }, [selectedTimesheet]);

  const dataStateChange = (event) => {
    set_list(process(timesheetDetails.slice(0), event.data));
    set_dataState(event.data);
  };

  // const calcWorkLengthInMin = (fromDate, toDate) => {
  //     const diffMs = toDate - fromDate; // milliseconds between
  //     const totalMinutes = Math.floor(diffMs / (1000 * 60));
  //     // const hours = Math.floor(totalMinutes / 60);
  //     // const minutes = totalMinutes % 60;
  //     return totalMinutes;
  //     /* if (totalMinutes < 0) {
  //         set_workLength("Start time must be before end time.")
  //     }
  //     else {
  //         set_workLength(hours + 'h ' + minutes + 'min')
  //     } */
  // }

  /* const getHoursSum = () => {
        let total = 0;
        timesheetDetails.forEach(val => {
            console.log('val:', val);
            const fromDate = new Date(val.from)
            const toDate = new Date(val.to)
            console.log('fromDate:', fromDate);
            console.log('toDate:', toDate);
            const totalMin = calcWorkLengthInMin(fromDate.getTime(), toDate.getTime());
            total += totalMin
        })

        console.log('total:', total);

        const fhours = Math.floor(total / 60);
        const fminutes = total % 60;

        //return timesheetDetails.reduce((prev, cur) => prev + cur.msgCount, 0);
        return `${fhours}h ${fminutes}min`;
    } */

  if (!selectedTimesheet) {
    return (
      <>
        <h3>Erfassung Leistungen</h3>
        <p>Wählen Sie einen Zeitraum aus</p>
      </>
    );
  }

  return (
    <>
      {<h3>Erfassung Stunden Status: {selectedTimesheet.state}</h3>}

      {
        //<h3> Einträge {timesheetDetails.length} Summe Stunden {getHoursSum()}</h3>
      }

      {timesheetDetails && (
        <LocalizationProvider language="de-DE">
          <IntlProvider locale="de-DE">
            <Grid
              data={list}
              {...dataState}
              rowHeight={23}
              onDataStateChange={dataStateChange}
              filterable
            >
              <GridToolbar>
                <Button
                  title="Neu"
                  className="k-button k-primary float-left"
                  icon="plus"
                  data-badge="50"
                  onClick={onClickNewTimesheet}
                  disabled={!isAllowCUD}
                >
                  Neu
                </Button>
                <div className="hours-alert-messaage">
                  {selectedTimesheet.sErrorMessage}
                  {/* Budget is{" "}
                  {Math.floor(selectedTimesheet.dTotalAllocatedTime / 60) +
                    ":" +
                    (selectedTimesheet.dTotalAllocatedTime % 60) +
                    " "}
                  hours per month, already entered are{" "}
                  {Math.floor(totalWorkTime / 60) +
                    ":" +
                    (totalWorkTime % 60) +
                    " "}{" "}
                  hours, leaving a rest of{" "}
                  {Math.floor(
                    (selectedTimesheet.dTotalAllocatedTime - totalWorkTime) / 60
                  ) +
                    ":" +
                    ((selectedTimesheet.dTotalAllocatedTime - totalWorkTime) %
                      60) +
                    " "}{" "}
                  hours */}
                </div>
              </GridToolbar>
              <GridColumn
                field="notok"
                title=" "
                cell={CustomNotOkCell}
                width="50px"
                filterable={false}
              />
              <GridColumn
                title=""
                cell={CustomEditRemoveCell}
                width="100px"
                filterable={false}
              />
              <GridColumn
                field="date"
                title="Datum"
                cell={KendoGridDateCell}
                width="100px"
                filterable={false}
                columnMenu={ColumnMenu}
              />
              <GridColumn
                field="from"
                title="Zeit von"
                cell={KendoGridDateCell2}
                width="100px"
                sortable={false}
                filterable={false}
              />
              <GridColumn
                field="to"
                title="Zeit bis"
                cell={KendoGridDateCell2}
                width="100px"
                sortable={false}
                filterable={false}
              />
              <GridColumn
                field="worktime"
                cell={CustomDauerCell}
                title="Dauer"
                width="200px"
                filterable={false}
                filter={"numeric"}
                columnMenu={ColumnMenu}
              />
              <GridColumn
                field="type"
                title="Leistungsart"
                cell={ObjectCell}
                filterable={false}
                filter={"text"}
                columnMenu={ColumnMenu}
              />
              <GridColumn
                field="detail"
                title="Leistungdetail"
                cell={ObjectCell}
                filterable={false}
                filter={"text"}
                columnMenu={ColumnMenu}
              />

              {/* not needed for now
                    <GridColumn field="comment" title="Freitext" /> */}
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      )}

      {showAreYouSureForDelete && (
        <Dialog title="Bestätigung" onClose={onAreYouSureToDelete_cancel}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            Sind Sie sicher, dass Sie den Eintrag löschen wollen?
          </p>
          <DialogActionsBar>
            <button className="k-button" onClick={onAreYouSureToDelete_cancel}>
              Nein
            </button>
            <button className="k-button" onClick={onAreYouSureToDelete_yes}>
              Ja
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}

class KendoGridDateCell extends React.Component {
  render() {
    const value = this.props.dataItem[this.props.field];
    return <td>{formatDate(new Date(value), "dd.MM.y")}</td>;
  }
}

class KendoGridDateCell2 extends React.Component {
  render() {
    const value = this.props.dataItem[this.props.field];
    return <td>{formatDate(new Date(value), "HH:mm")}</td>;
  }
}

class ObjectCell extends React.Component {
  render() {
    const value = this.props.dataItem[this.props.field];
    return (
      <td>
        {
          // value && value.text ? value.text : ''
          value || ""
        }
      </td>
    );
  }
}

class CustomNotOkCell extends React.Component {
  render() {
    const value = this.props.dataItem[this.props.field];
    return (
      <td>
        <span
          className={
            value === true
              ? "k-badge k-icon-md h-25 w-25 border-radius  bg-red"
              : ""
          }
        ></span>
      </td>
    );
  }
}

class CustomDauerCell extends React.Component {
  render() {
    let ret = "0 min";
    const worktime = this.props.dataItem[this.props.field];
    if (worktime != null && worktime > 0) {
      const hours = Math.floor(worktime / 60);
      const minutes = worktime % 60;
      ret = hours + "h " + minutes + "min";
    }
    return <td>{ret}</td>;
  }
}
