import React from 'react'
import "./impressum.css";
function Impressum() {
    return (
        <div style={{ marginLeft:"20px" }}    >
            <h1>Impressum</h1>
<div> 
<h4>Betreiber der Website</h4>
<p>Wellenbrecher e.V., Arminiusstr. 15, 44149 Dortmund<br />Tel (0231) 18292-0, Fax (0231) 18292-99,<br />e-mail: <a href="mailto:info@wellenbrecher.de">info@wellenbrecher.de</a></p>
<p>Wellenbrecher e.V. ist eingetragen im Vereinsregister des Amtsgerichtes Dortmund</p>
<h4>Verantwortlich für den Inhalt</h4>
<p>Joachim Glörfeld, Wolfgang Müller</p>
<h4>Webdesign und Programmierung</h4>
<p>INTRAC Informationssysteme GmbH, Ackerstr.44 41516 Grevenbroich<br/>Tel (02131) 94250<br/> <a href="mailto:info@intrac.de">info@intrac.de</a></p>
</div>

             
            </div>
    )
    
}

export default Impressum
