import React, { } from 'react';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import './TopBar.css';
import { API_URL } from '../utils/API';
import { AuthService } from '../utils/auth';

export default function TopBar(props) {

    const handleSelect = (e) => {
        if (e.item.text === 'Ausloggen') {
            AuthService.logout()
        } else  if (e.item.text === 'Kennwort ändern') {
            window.location.href = '/ChangePassword';
        }else  if (e.item.text === 'Impressum') {
            window.location.href = '/Impressum';
        }else  if (e.item.text === 'Datenschutzerklärung') {
            window.location.href = '/Datenschutzerklärung';
        }
    }

    return (
        <div className="TopBar">
            <div className="TopBar-left">
                <p>Erfassung Leistungsnachweise</p>
            </div>

            <div className="TopBar-second">
                {/* {children} */}
                </div>

            <div className="TopBar-right">

                {
                    AuthService.isAuthenticated() &&
                    <Menu onSelect={handleSelect}>
                        {/* <MenuItem text={`Hallo username`} icon="user"> */}
                        <MenuItem text={'Hallo ' + AuthService.userData.name} icon="user">
                            {/* <MenuItem text="Einstellungen" icon="gear" /> */}
                            <MenuItem text="Kennwort ändern" icon="lock" /> 
                            <MenuItem text="Ausloggen" icon="logout" />
                            <MenuItem  text="Impressum"/> 
                            <MenuItem text="Datenschutzerklärung"/>
                            <MenuItem text={'ENV: ' + process.env.NODE_ENV} icon="info" disabled={true} />
                            <MenuItem text={'API URL: ' + API_URL} icon="info" disabled={true} />
                        </MenuItem>
                    </Menu>}
            </div>
        </div>
    );
}
