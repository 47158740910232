import React, { useEffect, useState } from "react";
import NewTimesheetDialog from "../components/NewTimesheetDialog";
import Notice from "../components/Notice";
import ProjectsPane from "../panes/ProjectsPane";
import TimesheetDetailsPane from "../panes/TimesheetDetailsPane";
import TimesheetsPane from "../panes/TimesheetsPane";
import Feeds from "../feeds/Feeds";

import {
  fetchProjects,
  getTimesheetDetails,
  getAllTimesheetDetails,
  postTimeSheet,
  putTimeSheet,
  deleteTimeSheetDetail,
  Status_neu,
  Status_rückfragen,
  updateStatus,
} from "../utils/API";
import "./Dashboard.css";

export default function Dashboard(props) {
  const [isLoading, set_isLoading] = useState(true);
  const [projects, set_projects] = useState([]);
  const [projectId, set_projectId] = useState(null);
  const [selectedProject, set_selectedProject] = useState(null);
  const [timesheets, set_timesheets] = useState(null);
  const [selectedTimesheet, set_selectedTimesheet] = useState(null);
  const [selectedTimesheetId, set_selectedTimesheetId] = useState(null);
  const [selectedTimesheetDetails, set_selectedTimesheetDetails] = useState([]);
  const [selectedAllTimesheetDetails, set_selectedAllTimesheetDetails] = useState([]);
  const [showDialog, set_showDialog] = useState(false);
  const [error, set_error] = useState(null);
  const [dialogEditingItem, set_dialogEditingItem] = useState(false);
  const [dialogEditingItemId, set_dialogEditingItemId] = useState(-1);
  const [isAllowCUD, set_isAllowCUD] = useState(false);
  const [isShowFeeds, set_isShowFeeds] = useState(false);
  const [addnr, set_addnr] = useState(null);
  const [status, set_status] = useState(0);
  const [showAllProjects, set_showAllProjects] = useState(0);
  const [showAllTimeSheets, set_showAllTimeSheets] = useState(0);
  const [minDate, set_minDate] = useState(new Date());
  const [maxDate, set_maxDate] = useState(new Date());
  useEffect(() => {
    loadProjects();

    return () => {};
  }, []); // This effect never re-runs

  useEffect(() => {
    loadProjects();
    return () => {};
  }, [showAllProjects, showAllTimeSheets]);

  const onProjectItemClick = (projectId, addnr, index) => {
    set_isShowFeeds(false);
    const project = projects.find((p) => p.projectId === projectId);

    set_projectId(projectId);
    set_addnr(addnr);
    set_selectedProject(project);
    set_timesheets(project.timesheets);

    // clear previous if any project was selected
    set_selectedTimesheet(null);
    set_selectedTimesheetId(null);
  };

  const onTimesheetsGridRowClick = async (event) => {
    set_minDate(
      new Date(
        new Date(event.dataItem.datum).getFullYear(),
        new Date(event.dataItem.datum).getMonth(),
        1
      )
    );
    set_maxDate(
      new Date(
        new Date(event.dataItem.datum).getFullYear(),
        new Date(event.dataItem.datum).getMonth() + 1,
        0
      )
    );
    let status = event.dataItem.state;
    if (status === Status_neu || status === Status_rückfragen) {
      set_isAllowCUD(true);
    } else {
      set_isAllowCUD(false);
    }

    set_selectedTimesheetId(event.dataItem.id);
    set_selectedTimesheet(event.dataItem);
    const response = await getTimesheetDetails(selectedProject, event.dataItem);

    if (response instanceof Error) {
      set_selectedTimesheetDetails([]);
      set_error(response);
    } else {
      set_selectedTimesheetDetails(response);
      set_error(null);
    }


    set_selectedTimesheetId(event.dataItem.id);
    set_selectedTimesheet(event.dataItem);
    const responseA = await getAllTimesheetDetails(selectedProject, event.dataItem);

    if (responseA instanceof Error) {
      set_selectedAllTimesheetDetails([]);
      set_error(responseA);
    } else {
      set_selectedAllTimesheetDetails(responseA);
      set_error(null);
    }
  };

  const onStatus_Change = async (value) => {
    const response = await updateStatus(value);
    if (response instanceof Error) {
      set_error(response);
    } else {
      if (response === 1) {
        set_selectedTimesheetId(value.id);
        loadProjects(value.id);
      }
    }
  };

  const onClickNewTimesheet = (e) => {
    set_dialogEditingItem(null);
    set_showDialog(true);
  };

  const onDialogCancel = () => {
    set_dialogEditingItem(null);
    set_showDialog(false);
  };

  const onDialogSave = async (params) => {
    if (dialogEditingItem) {
      const response = await putTimeSheet(
        selectedProject,
        selectedTimesheet,
        params
      );
      if (response instanceof Error) {
        set_error(response);
      } else {
        if (response === 1) {
          loadProjects(dialogEditingItem.timid);
        } else {
          alert(
            "Can't save timesheet detail as there is no timesheet selected"
          );
        }
      }
    } else {
      if (selectedTimesheet) {
        const response = await postTimeSheet(
          selectedProject,
          selectedTimesheet,
          params
        );
        if (response instanceof Error) {
          set_error(response);
        } else {
          loadProjects(selectedTimesheet.id);
        }
      } else {
        alert("Can't save timesheet detail as there is no timesheet selected");
      }
    }

    set_dialogEditingItem(null);
    set_dialogEditingItemId(-1);
    set_showDialog(false);
  };

  const onTimesheetDetail_edit = (data) => {
    set_dialogEditingItem(data);
    set_dialogEditingItemId(data.id);
    set_showDialog(true);
  };

  const onTimesheetDetail_delete = async (timesheetDetailDataId) => {
    const response = await deleteTimeSheetDetail(
      selectedProject,
      selectedTimesheet,
      timesheetDetailDataId
    );
    if (response instanceof Error) {
      set_error(response);
    } else {
      if (response === "1") {
        // let index = selectedTimesheetDetails.findIndex(x => x.id === timesheetDetailDataId);
        // selectedTimesheetDetails.splice(index, 1);
        // set_timesheets([...selectedProject.timesheets]);
        let timesheetDetail = selectedTimesheetDetails.find(
          (x) => x.id === timesheetDetailDataId
        );
        if (timesheetDetail !== undefined) {
          loadProjects(timesheetDetail.timid);
        }
      }
    }
  };

  const handleFeeds = () => {
    set_isShowFeeds(true);
  };

  const onShowAllProjectsSwitchChange = (e) => {
    if (e.value) {
      set_showAllProjects(1);
    } else {
      set_showAllProjects(0);
    }
  };

  const onShowAllTimeSheetsSwitchChange = (e) => {
    if (e.value) {
      set_showAllTimeSheets(1);
    } else {
      set_showAllTimeSheets(0);
    }
  };

  return (
    <div className="Dashboard">
      {/* TEST ERROR NOTIFICATION */}
      {/* <button onClick={e => set_error(new Error('This is example error.'))}>show err</button> */}

      <Notice error={error} onClose={() => set_error(null)} status={status} />

      {showDialog && (
        <NewTimesheetDialog
          editingItem={dialogEditingItem}
          onDialogCancel={onDialogCancel}
          onDialogSave={(p) => onDialogSave(p)}
          selectedProject={selectedProject}
          addnr={addnr}
          minDate={minDate}
          maxDate={maxDate}
          selectedTimesheet={selectedTimesheet}
          timesheetDetails={selectedTimesheetDetails}
          timesheetAllDetails={selectedAllTimesheetDetails}
        />
      )}

      <div className="leftCol">
        {isLoading && (
          <React.Fragment>
            <div className="projectsLoader">
              <p>Projekte werden geladen...</p>
              <br />
              <span
                className="k-icon k-i-loading"
                style={{ fontSize: "48px" }}
              ></span>
            </div>
          </React.Fragment>
        )}
        {!isLoading && (
          <ProjectsPane
            projects={projects}
            selectedProject={selectedProject}
            onProjectItemClick={(pid, addnr, idx) =>
              onProjectItemClick(pid, addnr, idx)
            }
            isShowAllProjects={showAllProjects}
            onSwitchChange={(e) => onShowAllProjectsSwitchChange(e)}
          />
        )}

        {/* <button
          type={"button"}
          className="k-button k-primary"
          onClick={handleFeeds}
        >
          Feeds
        </button> */}
      </div>

      <div className="rightCol">
        {!selectedProject && !isShowFeeds && (
          <p>Wählen Sie ein Projekt für weitere Informationen</p>
        )}
        {selectedProject && !isShowFeeds && (
          <TimesheetsPane
            projectId={selectedProject.projectId}
            clientName={selectedProject.clientName}
            timesheets={timesheets}
            selectedTimesheetId={selectedTimesheetId}
            onTimesheetsGridRowClick={(e) => onTimesheetsGridRowClick(e)}
            onStatus_Change={(e) => onStatus_Change(e)}
            isShowAllTimeSheets={showAllTimeSheets}
            onSwitchChange={(e) => onShowAllTimeSheetsSwitchChange(e)}
          />
        )}
        {selectedProject && !isShowFeeds && (
          <TimesheetDetailsPane
            selectedTimesheet={selectedTimesheet}
            timesheetDetails={selectedTimesheetDetails}
            timesheetAllDetails={selectedAllTimesheetDetails}
            onClickNewTimesheet={onClickNewTimesheet}
            onEdit={(trid) => onTimesheetDetail_edit(trid)}
            onDelete={(trid) => onTimesheetDetail_delete(trid)}
            isAllowCUD={isAllowCUD}
          />
        )}

        {/* {isShowFeeds && <Feeds />} */}
      </div>
    </div>
  );

  async function loadProjects(timesheetId = 0) {
    set_isLoading(true);

    const response = await fetchProjects(showAllProjects, showAllTimeSheets);

    if (response instanceof Error) {
      set_error(response);
    } else {
      set_projects(response);
      if (projectId) {
        const project = response.find((p) => p.projectId === projectId);
        set_projectId(projectId);
        set_selectedProject(project);
        set_timesheets(project.timesheets);
        if (timesheetId !== 0) {
          const timesheet = project.timesheets.find(
            (p) => p.id === timesheetId
          );
          set_selectedTimesheetId(timesheetId);
          set_selectedTimesheet(timesheet);
          if(timesheet!==undefined && timesheet.timesheetdet!==undefined){
         set_selectedTimesheetDetails(timesheet.timesheetdet);
         set_selectedAllTimesheetDetails(timesheet.timesheetdet);
        }
        }
      }
    }
    set_isLoading(false);
  }
}
